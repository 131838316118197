<template>
    <div style="background-color: #f5faff">
        <titleBar
        style="max-height: 50px;margin-bottom: 10px;"
        :username="currentUserName"
        :pageName="pageName"
        ></titleBar>
        <!-- Dialog Block Start -->
        <div>
            <!-- Create Offer Dialog Start -->
            <v-row>
                <v-dialog
                v-model="createOffersDialog"
                persistent :overlay="true"
                max-width="1024px"
                class="createOfferDialog"
                width="90%"
                >
                    <v-card class="py-10">
                        <v-card-title primary-title class="d-flex justify-space-between ">
                            <div>
                                <h3 class="headline mb-0">Create Offers</h3>
                            </div>
                            <div>
                                <img @click="createOffersDialog= false, clearVariableOnSuccess()" src="https://s3iconimages.mymedicine.com.mm/popupClose.svg">
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-form v-model="createFormData">
                            <div class="pa-2">
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left font-weight-bold" style="">Offer Name</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            placeholder="Offer Name(EN)" 
                                            :rules="[rules.required, rules.nameLength]"
                                            v-model="offerNameEN"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Offer Name(MM)</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :rules="[rules.required, rules.mmNameLength]"
                                            placeholder="Offer Name(MM)" 
                                            v-model="offerNameMM"></v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <div>
                                        <p class="ma-0 text-left">Offer</p>
                                        <p class="text-left"> Description(EN)</p>
                                    </div>
                                    <div class="ml-2" style="width: 80%;">
                                        <v-textarea 
                                        rows="1" 
                                        :rules="[rules.required, rules.descriptionLength]"
                                        v-model="offerDescriptionEN"></v-textarea>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <div>
                                        <p class="ma-0 text-left">Offer</p>
                                        <p class="text-left"> Description(MM)</p>
                                    </div>
                                    <div class="ml-2" style="width: 80%;">
                                        <v-textarea 
                                        rows="1" 
                                        :rules="[rules.required, rules.mmDescriptionLength]"
                                        v-model="offerDescriptionMM"></v-textarea>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex createOffersWidth" style="margin-top:2%;">
                                        <div style="margin-top:2% ;">
                                            <p class="text-left">Offer Image</p>
                                        </div>
                                        <div class="ml-10" style="width: 100% !important;">
                                            <v-file-input 
                                            v-model="offerImage"
                                            label="Click Here To Upload Offer Banner" 
                                            prepend-icon=""
                                            variant="solo"
                                            @change="getBase64"
                                            >
                                            </v-file-input>
                                        </div>
                                    </div>
                                    <div class="createOffersWidth">
                                        <img width="250px" v-if="!imageLoading" :src=offerImagePreview>
                                        <v-progress-circular
                                        v-else
                                        indeterminate
                                        color="primary"
                                        ></v-progress-circular>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" >
                                            <p class="text-left">Offer type</p>
                                        </div>
                                        <div class="ml-10 d-flex">
                                            <v-radio-group
                                            v-model="offerType"
                                            :rules="[rules.required]"
                                            row>
                                                <v-radio
                                                label="Promotion"
                                                value="PROMOTION"
                                                class="offerTypeRadio"
                                                ></v-radio>
                                                <v-radio
                                                label="Coupon"
                                                value="COUPON"
                                                class="offerTypeRadio"
                                                ></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth" v-if="offerType === 'COUPON'">
                                        <div class="mt-2">
                                            <p class="text-left">Coupon Code</p>
                                        </div>
                                        <div class="ml-7">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            :rules="[rules.required]"
                                            label="Enter Coupon Code" 
                                            v-model="couponCode"></v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mt-4">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Issue Quantity</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            style="width: 200px;"
                                            outline 
                                            type="Number" 
                                            label="Total Issue Quantity Amount" 
                                            :rules="[rules.required]"
                                            v-model="issueQuantity"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Issue Quantity/person</p>
                                        </div>
                                        <div>
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            type="Number" 
                                            label="Redemption Per User" 
                                            v-model="issueQuantityPerPerson"></v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="d-flex align-center">
                                        <div>
                                            <p class="text-left ma-0">Discount</p>
                                            <p class="text-left">Scope</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            style="width: 200px;"
                                            :items="discountScopeItems"
                                            item-text="scopeText"
                                            item-value="scopeValue"
                                            v-model="selectedDiscountScope"
                                            label="Discount Scope"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="margin-left: 17% ;">
                                        <div>
                                            <v-autocomplete
                                            :search-input.sync="searchSpecialization"
                                            @input="searchSpecialization = ''"
                                            @blur="getDoctorsListForSpecializationList()"
                                            :items="listOfSpecialization"
                                            item-text = 'specialization_name'
                                            item-value="_id"
                                            chips
                                            v-model="selectedSpecialization"
                                            label="Specialization"
                                            multiple
                                            :rules="[rules.required]"
                                            ></v-autocomplete>
                                        </div>
                                        <div class="ml-4" v-if="selectedDiscountScope === 'DOCTORS'">
                                            <div v-if="!loadingDoctorList">
                                                <v-autocomplete
                                                :search-input.sync="searchDoctor"
                                                @input="searchDoctor = ''"
                                                :items="listOfDoctor"
                                                item-text="doctor_name"
                                                item-value="_id"
                                                chips
                                                v-model="selectedDoctor"
                                                multiple
                                                label="Doctors"
                                                :rules="[rules.required]"
                                                ></v-autocomplete>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between ">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">User Type</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            :items="listOfUserType"
                                            item-text="userTypeText"
                                            item-value="userTypeValue"
                                            v-model="selectedUserType"
                                            label="Select User Type"
                                            style="width: 200px;"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth" v-if="false">
                                        <div class="mt-2">
                                            <p class="text-left">Whitelist Group</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-select
                                            :items="listOfWhiteListedGroup"
                                            item-text="pk"
                                            v-model="selectedWhitelistedGroup"
                                            label="Select Whitelist Group"
                                            ></v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Discount Type</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-select
                                            dense
                                            :items="typeOfDiscount"
                                            item-text="offerText"
                                            item-value="offerTypeValue"
                                            v-model="selectedDiscountType"
                                            label="Select Offer Type"
                                            style="width: 200px;"
                                            :rules="[rules.required]"
                                            ></v-select>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth" v-if="selectedDiscountType">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Discount Value</p>
                                        </div>
                                        <div v-if="selectedDiscountType == 'PERCENTAGE_OFF'">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            prefix="%"
                                            label="Discount Amount" 
                                            :rules= "[rules.percentLimit, rules.required]"
                                            v-model="discountValue"></v-text-field>
                                        </div>
                                        <div v-if="selectedDiscountType == 'FLAT_OFF'">
                                            <v-text-field 
                                            class="ma-0" 
                                            dense 
                                            outline 
                                            prefix="MMK"
                                            label="Discount Amount" 
                                            :rules= "[rules.required, rules.zeroValue]"
                                            v-model="discountValue"></v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mt-4" v-if="selectedDiscountType">
                                    <div class="d-flex align-center createOffersWidth" v-if="selectedDiscountType == 'PERCENTAGE_OFF'">
                                        <div class="mt-2">
                                            <p class="text-left">Maximum Amount</p>
                                        </div>
                                        <div class="ml-4">
                                            <v-text-field 
                                            class="ma-0" 
                                            style="width:200px"
                                            dense
                                            prefix="MMK"
                                            :rules= "[rules.required]" 
                                            outline 
                                            type="Number" 
                                            label="Maximum Amount" 
                                            v-model="maximumDiscountAmount"></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2" style="width: 35%;">
                                            <p class="text-left">Minimum spent</p>
                                        </div>
                                        <div>
                                            <v-text-field 
                                            :rules= "[rules.required]"
                                            class="ma-0" 
                                            dense 
                                            prefix="MMK"
                                            outline 
                                            type="Number" 
                                            label="Minimum Amount" 
                                            v-model="minimumDiscountAmount"></v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mt-4">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Start Date</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field 
                                                    v-model="offerStartDate" 
                                                    readonly
                                                    :rules= "[rules.required]" 
                                                    label="Offer Start Date" 
                                                    background-color="#F4F6FA"  
                                                    v-bind="attrs" 
                                                    v-on="on" ></v-text-field>
                                                </template>
                                                <v-date-picker style="padding:20px; height: 500px;color: black" v-model="offerStartDate" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">Start Time</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu
                                                ref="startTimeMenu"
                                                v-model="startTimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="startTime"
                                                transition="scale-transition"
                                                offset-y
                                                :rules= "[rules.required]" 
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="startTime"
                                                    label="Offer Start Time"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    :rules= "[rules.required]" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="startTimeMenu"
                                                v-model="startTime"
                                                full-width
                                                @click:minute="$refs.startTimeMenu.save(startTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex" v-if="offerStartDate">
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">End Date</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field 
                                                    :rules= "[rules.required]"
                                                    v-model="offerEndDate" 
                                                    readonly 
                                                    label="Offer End Date" 
                                                    background-color="#F4F6FA"  
                                                    v-bind="attrs" 
                                                    v-on="on" ></v-text-field>
                                                </template>
                                                <v-date-picker style="padding:20px; height: 500px;color: black" v-model="offerEndDate" show-adjacent-months :allowed-dates="allowedEndDates"></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                    <div class="d-flex align-center createOffersWidth">
                                        <div class="mt-2">
                                            <p class="text-left">End Time</p>
                                        </div>
                                        <div class="ml-10">
                                            <v-menu
                                                ref="endTimeMenu"
                                                v-model="endTimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :rules= "[rules.required]" 
                                                :return-value.sync="endTimeMenu"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="endTime"
                                                    label="Offer End Time"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    :rules= "[rules.required]" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="endTimeMenu"
                                                v-model="endTime"
                                                full-width
                                                @click:minute="$refs.endTimeMenu.save(endTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="d-flex flex-row-reverse mr-10">
                            <v-btn 
                            color="primary" 
                            width="20%" 
                            :disabled="isOfferValid()"
                            @click="createOffer()">Create</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Create Offer Dialog End -->
        </div>
        <!-- Dialog Block End -->
        <v-app>
            <div style="background-color: #f5faff; height: 100vh;">
                <v-row cols="12">
                    <v-col cols="2">
                        <sideNavBar :pageName="pageName"></sideNavBar>
                    </v-col>
                    <v-col cols="10" style="overflow:scroll; height:93vh;">
                        <div style="margin: 20px 20px 20px 50px ">
                            <div class="d-flex" style="justify-content: space-between;">
                                <div style="width: 30%" align="left">
                                    <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2">
                                    <h3>View all Offers</h3>
                                    </span>
                                </div>
                                <div class="d-flex ma-5">
                                    <div class="mr-2">
                                        <v-btn class="primary" @click="getCreateOfferDetails()">Add Offer</v-btn>
                                    </div>
                                    <div class="ml-2">
                                        <v-btn class="primary" @click="downloadOffersByStatus()">
                                            Download
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <v-tabs 
                                fixed-tabs 
                                v-model="offersStatus" 
                                :ripple="true" 
                                class="fixed-tabs-bar benefitTabClass"  return-object>
                                    <v-tabs-slider :ripple="true"></v-tabs-slider>
                                    <v-tab
                                    v-model="tabModel"
                                    v-for="tabTitles in offerTabTitle"
                                    :key="tabTitles"
                                    :href="'#'+tabTitles"
                                    :disabled="tabDataLoading"
                                    @change="getOffersByStatus(tabTitles), renderTableCustomerActions(tabTitles), lastEvaluatedKey = null"
                                    >
                                        {{ tabTitles }}
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="offersStatus">
                                    <v-tab-item id="Active">
                                        <tableComponenet 
                                        :tableTitle="tableHeaders" 
                                        :tableItem = "offerDetails" 
                                        :customeSlotName = "customeSlotName"
                                        :isDataLoading = 'isDataLoading'
                                        :endOfDataObject = 'endOfData'
                                        @actionClicked ="callRespectiveAPI"
                                        @selectFunctionEmited="changeStatusOfOffer"
                                        @paginationEmitted ="lastEvaluatedKey">
                                        </tableComponenet>
                                    </v-tab-item>
                                    <v-tab-item id="Inactive">
                                        <tableComponenet 
                                        :tableTitle="tableHeaders" 
                                        :tableItem = "offerDetails" 
                                        :customeSlotName = "customeSlotName"
                                        :isDataLoading = 'isDataLoading'
                                        :endOfDataObject = 'endOfData'
                                        @actionClicked ="callRespectiveAPI"
                                        @selectFunctionEmited="changeStatusOfOffer"
                                        @paginationEmitted ="statusLastEvaluatedKey" >
                                        </tableComponenet>
                                    </v-tab-item>
                                    <v-tab-item id="Draft">
                                        <tableComponenet 
                                        :tableTitle="tableHeaders" 
                                        :tableItem = "offerDetails"  
                                        :customeSlotName = "customeSlotName"
                                        :isDataLoading = 'isDataLoading'
                                        :endOfDataObject = 'endOfData'
                                        @actionClicked ="callRespectiveAPI"
                                        @selectFunctionEmited="changeStatusOfOffer"
                                        @paginationEmitted ="statusLastEvaluatedKey" >
                                        </tableComponenet>
                                    </v-tab-item>
                                    <v-tab-item id="Archive">
                                        <tableComponenet 
                                        :tableTitle="archiveTableHeaders" 
                                        :tableItem = "offerDetails" 
                                        :customeSlotName = "customeSlotName"
                                        :isDataLoading = 'isDataLoading'
                                        :endOfDataObject = 'endOfData'
                                        @actionClicked ="callRespectiveAPI"
                                        @selectFunctionEmited="changeStatusOfOffer"
                                        @paginationEmitted ="statusLastEvaluatedKey" >
                                        </tableComponenet>
                                    </v-tab-item>
                                </v-tabs-items>       
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>
<script>
import axios from 'axios';
import { create } from 'domain';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';
export default {
    name: "allOffersPage",
    components: {
        titleBar: () => import('../../navbar.vue'),
        sideNavBar: () => import('../../sideBar.vue'),
        tableComponenet: () => import('../../../components/tableComponent.vue')
    },
    data() {
        return {
            pageName: 'Offers',
            currentUserName: null,
            currentUserId: null,
            offersStatus: 0,
            offerTabTitle: ['Active', 'Inactive', 'Draft', 'Archive'],
            tabModel: null,
            offerNameEN: '',
            offerNameMM: '',
            offerDescriptionEN:'',
            offerDescriptionMM:'',
            offerImageB64:'',
            offerImage: null,
            offerImagePreview: '',
            createFormData: false,
            offerType:'',
            issueQuantity:'',
            issueQuantityPerPerson:'',
            discountScopeItems:[{scopeText:'Doctors', scopeValue:'DOCTORS'}, {scopeText:'Specialization', scopeValue:'SPECIALIZATIONS'}],
            selectedDiscountScope:'',
            listOfSpecialization:[],
            selectedSpecialization:[],
            listOfDoctor:[],
            selectedDoctor:[],
            loadingDoctorList: false,
            listOfUserType:[{userTypeText:'All', userTypeValue:'ALL'}, {userTypeText:'New', userTypeValue:'NEW'},{userTypeText:'Existing', userTypeValue:'EXISTING'}],
            selectedUserType:'',
            listOfWhiteListedGroup:[],
            selectedWhitelistedGroup:'',
            typeOfDiscount:[{offerText:'Percentage Off', offerTypeValue: 'PERCENTAGE_OFF'}, {offerText:'Flat Off', offerTypeValue: 'FLAT_OFF'}],
            selectedDiscountType:'',
            discountValue:'',
            maximumDiscountAmount:'',
            minimumDiscountAmount:'',
            offerStartDate:'',
            offerEndDate:'',
            startDateMenu:false,
            searchSpecialization:'null',
            searchDoctor: 'null',
            endDateMenu:false,
            startTimeMenu: false,
            startTime: '00:00',
            currentStatusTab:'Active',
            endTimeMenu: false,
            endTime: '00:00',
            responseOfferDetails:[],
            isCreateButtonClicked: false,
            isDataLoading: true,
            tabDataLoading: false,
            lastEvaluatedKey:'',
            statusLastEvaluatedKey:'',
            couponCode:'',
            draftChangeStatus:[{statusName:'Active', statusValue: 'ACTIVE'}, {statusName:'Archive', statusValue: 'ARCHIVE'}],
            activeChangeStatus:[{statusName:'Inactive', statusValue: 'INACTIVE'}, {statusName:'Draft', statusValue: 'DRAFT'}],
            inactiveChangeStatus:[{statusName:'Active', statusValue: 'ACTIVE'}],
            rules:{
                required: (value) => !!value|| 'This Field is Required',
                nameLength: (value) => (value && value.length < 120) || '120 Charater Max',
                mmNameLength: (value) => value.length <=140 || 'Name is to large',
                descriptionLength: (value) => value.length <= 120 || '120 Charater Max',
                mmDescriptionLength: value => value.length <= 120 || '120 Charater Max',
                percentLimit: value => (value <= 100) || 'Percentage should not be more than 100',
                percentMaxDiscount: value => !!value || 'Percentage discount needs Max dicount value',
                zeroValue: value => value != 0 || 'Value cannot be 0'
            },
            tableHeaders: [
                { text: 'Offer Name', value: 'offer_name', sortable: false},
                { text: 'Start Date', value: 'start_date', sortable: false },
                { text: 'End Date', value: 'end_date', sortable: false },
                { text: 'Created By', value: 'created_by', sortable: false },
                { text: 'Change Status', value: 'statusAction', sortable: false },
                { text: 'Actions', value: 'actions', class:'actionClass', sortable: false}
            ], 
            archiveTableHeaders: [
                { text: 'Offer Name', value: 'offer_name', sortable: false},
                { text: 'Start Date', value: 'start_date', sortable: false },
                { text: 'End Date', value: 'end_date', sortable: false },
                { text: 'Created By', value: 'created_by', sortable: false },
                { text: 'Actions', value: 'actions', class:'actionClass', sortable: false}
            ], 
            offerDetails: [],
            customeSlotName: [],
            createOffersDialog: false,
            imageLoading: false,
            endOfData: false,
            fileTypes: ["image/jpeg", "image/png"],
            isButtonClicked: false
        }
    },
    beforeMount(){
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
                name: "AdminLogin",
            });
        } else {
            var getAdminBody = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType: "ADMIN"
            };
            axios_auth_instance_admin.post("/getAdmin", getAdminBody)
            .then((getAdminResponse) => {
                this.currentUserName = getAdminResponse.data.data.admin_name;
                this.currentUserId = getAdminResponse.data.data._id;
                this.renderTableCustomerActions('Active')
            })
            .catch((getAdminError) => {
                handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
            });
        }
    },
    mounted() {
        var offersHomePageBody = {
                token : this.currentUser,
                typeOfUser: 'ADMIN',
                lastEvaluatedKey: this.lastEvaluatedKey ? JSON.stringify(this.lastEvaluatedKey) : '',
                pageLimit: 10
            };
            axios_auth_instance_admin.get( "/offersHomePage", {params:offersHomePageBody})
            .then((offersHomePageResponse) => {
                this.isDataLoading = false;
                this.responseOfferDetails = offersHomePageResponse.data.active_offers;
                this.lastEvaluatedKey = offersHomePageResponse.data.last_evaluated_key;
                if(this.lastEvaluatedKey == null || this.lastEvaluatedKey == undefined) {
                    this.endOfData = true;
                }
                this.responseOfferDetails.forEach(offerDetailsObject => {
                    this.offerDetails.push({
                        'offer_code': offerDetailsObject.offer_code,
                        'offer_name': offerDetailsObject.offer_name,
                        'start_date': offerDetailsObject.start_date,
                        'end_date': offerDetailsObject.end_date,
                        'created_by': offerDetailsObject.createdBy,
                        'createdAt': offerDetailsObject.createdAt,
                        'actionValue':''
                    })
                });
            })
            .catch((offersHomePageError) => {
                handleError(offersHomePageError, this.$router, 'offersHomePage', 'AdminLogin', 'token');
            })
    },
    watch: {
        lastEvaluatedKey() {
            this.lastEvaluatedKey != null || this.lastEvaluatedKey != undefined ?  this.offersHomePageAPI() :  {} ;
            deep: true
        },
        statusLastEvaluatedKey(){
            this.statusLastEvaluatedKey != null || this.statusLastEvaluatedKey != undefined ?  this.getOffersByStatus(this.currentStatusTab) : {} ;
            deep: true
        },
        currentStatusTab(){
            this.offerDetails = [];
            deep: true
        },

    },
    methods: {
        async getBase64(){
            // get the files
            if (this.offerImage) {
                try {
                    this.offerImagePreview = URL.createObjectURL(this.offerImage);
                    this.offerImageB64 =  await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(this.offerImage);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                    });
                } catch (Exception) {
                    this.offerImagePreview = null;
                }
            } else {
                this.offerImagePreview = null;
            }
        
        },
        callRespectiveAPI(functionName, rowObject){
            this[functionName](rowObject);
        },
        allowedDates(val) 
        { 
            return val >= new Date().toISOString().split("T")[0] 
        },
        allowedEndDates(val){
            return val > new Date(this.offerStartDate).toISOString().split("T")[0]
        },
        offersHomePageAPI(){
            this.tabDataLoading = true;
            var offersHomePageBody = {
                token : this.currentUser,
                typeOfUser: 'ADMIN',
                lastEvaluatedKey: this.lastEvaluatedKey ? JSON.stringify(this.lastEvaluatedKey) : '',
                pageLimit: 10
            };
            axios_auth_instance_admin.get("/offersHomePage", {params:offersHomePageBody})
            .then((offersHomePageResponse) => {
                this.isDataLoading = false;
                this.responseOfferDetails = offersHomePageResponse.data.active_offers;
                this.lastEvaluatedKey = offersHomePageResponse.data.last_evaluated_key;
                this.responseOfferDetails.forEach(offerDetailsObject => {
                    this.offerDetails.push({
                        'offer_code': offerDetailsObject.offer_code,
                        'offer_name': offerDetailsObject.offer_name,
                        'start_date': offerDetailsObject.start_date,
                        'end_date': offerDetailsObject.end_date,
                        'created_by': offerDetailsObject.createdBy,
                        'createdAt': offerDetailsObject.createdAt,
                        'actionValue':''
                    })
                });
                this.tabDataLoading = false;
            })
            .catch((offersHomePageError) => {
                handleError(offersHomePageError, this.$router, 'offersHomePage', 'AdminLogin', 'token');
                this.tabDataLoading = false;
            })
        },
        getOffersByStatus(tabDetails){
            this.tabDataLoading = true;
            if (tabDetails) {
                this.isDataLoading = true;
                var getOffersByStatusBody = {
                    token: this.currentUser,
                    typeOfUser: 'ADMIN',
                    pageLimit: 10,
                    status: tabDetails.toUpperCase(),
                    lastEvaluatedKey: this.statusLastEvaluatedKey ? JSON.stringify(this.statusLastEvaluatedKey) : '',
                }
                axios_auth_instance_admin.get('/getOffersByStatus', {params: getOffersByStatusBody})
                .then((getOffersByStatusResponse) => {
                    this.isDataLoading = false;
                    this.responseOfferDetails = getOffersByStatusResponse.data.offers_list;
                    this.statusLastEvaluatedKey = getOffersByStatusResponse.data.last_evaluated_key
                    if(this.statusLastEvaluatedKey == null || this.statusLastEvaluatedKey == undefined) {
                        this.endOfData = true
                    }
                        this.responseOfferDetails.forEach(offerDetailsObject => {
                            this.offerDetails.push({
                                'offer_code': offerDetailsObject.offer_code,
                                'offerCurrentStatus': this.currentStatusTab,
                                'offer_name': offerDetailsObject.offer_name,
                                'start_date': offerDetailsObject.start_date,
                                'end_date': offerDetailsObject.end_date,
                                'created_by': offerDetailsObject.createdBy,
                                'createdAt': offerDetailsObject.createdAt,
                                'actionValue':''
                            });
                        });
                    this.tabDataLoading = false;
                })
                .catch((getOffersByStatusError) =>{
                    handleError(getOffersByStatusError, this.$router, 'getOffersByStatus', 'AdminLogin', 'token');
                    this.tabDataLoading = false;
                })
            }
        },
        renderTableCustomerActions(tabName){
            this.currentStatusTab = tabName.toUpperCase();
            if(tabName === 'Draft'){
                let changeStatus = {
                    'headerValue':'statusAction',
                    'ComponenetType': 'vSelect',
                    'objectToRender':{
                        optionList: this.draftChangeStatus,
                        optionText: 'statusName',
                        optionValue: 'statusValue',
                    }
                }
                let actionsButtons = {
                    'headerValue':'actions',
                    'ComponenetType': 'button',
                    'objectToRender': [
                        {
                            'iconSrc' : "https://s3iconimages.mymedicine.com.mm/show_pwd.svg",
                            'functionToEmit': 'viewParticularOffer'
                        }
                    ],
                }
                this.customeSlotName.push(changeStatus, actionsButtons)
            } else if (tabName === 'Active'){
                let changeStatus = {
                    'headerValue':'statusAction',
                    'ComponenetType': 'vSelect',
                    'objectToRender':{
                        optionList: this.activeChangeStatus,
                        optionText: 'statusName',
                        optionValue: 'statusValue',
                    }
                }
                let actionsButtons = {
                    'headerValue':'actions',
                    'ComponenetType': 'button',
                    'objectToRender': [
                    {
                            'iconSrc' : "https://s3iconimages.mymedicine.com.mm/show_pwd.svg",
                            'functionToEmit': 'viewParticularOffer'
                        }
                    ],
                }
                this.customeSlotName.push(changeStatus, actionsButtons)
            }else if (tabName === 'Inactive'){
                let changeStatus = {
                    'headerValue':'statusAction',
                    'ComponenetType': 'vSelect',
                    'objectToRender':{
                        optionList: this.inactiveChangeStatus,
                        optionText: 'statusName',
                        optionValue: 'statusValue',
                    }
                }
                let actionsButtons = {
                    'headerValue':'actions',
                    'ComponenetType': 'button',
                    'objectToRender': [
                    {
                            'iconSrc' : "https://s3iconimages.mymedicine.com.mm/show_pwd.svg",
                            'functionToEmit': 'viewParticularOffer'
                        }
                    ],
                }
                this.customeSlotName.push(changeStatus, actionsButtons)
            }
        },
        changeStatusOfOffer( statusValue, offerCode){
            this.tabDataLoading = true;
            this.isDataLoading = true;
            var modifyOfferStatusBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                offerCode: offerCode,
                currentStatus: this.currentStatusTab,
                changeStatus: statusValue
            };
            axios_auth_instance_admin.patch('/modifyOfferStatus', modifyOfferStatusBody)
            .then((modifyOfferStatusResponse) =>{ 
                this.tabDataLoading = false;
                this.isDataLoading = false;
                this.offerDetails = [];
                this.getOffersByStatus(this.currentStatusTab);
            })
            .catch((modifyOfferStatusError) =>{ 
                handleError(modifyOfferStatusError, this.$router, 'modifyOfferStatus', 'AdminLogin', 'token');
                if (modifyOfferStatusError.response.data.info === 'ACTIVE OFFER') {
                    window.alert("Cannot change to draft for an ongoing offer");
                }
                this.tabDataLoading = false;
                this.isDataLoading = false;
            })
        },
        getCreateOfferDetails(){
            this.createOffersDialog = true
            var createOfferPageBody = {
                token : this.currentUser,
                typeOfUser: 'ADMIN'
            };
            axios_auth_instance_admin.get( '/createOfferPage', {params:createOfferPageBody})
            .then((createOfferPageResponse) => {
                this.listOfSpecialization = createOfferPageResponse.data.specialization_list;
                this.listOfWhiteListedGroup = createOfferPageResponse.data.whitelist_data
            })
            .catch((createOfferPageError) => {
                handleError(createOfferPageError, this.$router, 'createOfferPage', 'AdminLogin', 'token');
            })
        },
        downloadOffersByStatus(){
            var downloadOffersBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                status: this.currentStatusTab.toUpperCase()
            };
            axios_auth_instance_admin.get('/downloadOffers', { params: downloadOffersBody })
            .then((downloadOffersResponse) => {
                alert(`File will shared to your registered E-Mail Id`);
            })
            .catch((downloadOffersError) => {
                handleError(downloadOffersError, this.$router, 'downloadOffers', 'AdminLogin', 'token');
            })
        },
        createOffer(){
            this.isButtonClicked = true;
            var createOfferBody = {
                token: this.currentUser,
                typeOfUser: 'ADMIN',
                offerName: this.offerNameEN,
                offerNameMM: this.offerNameMM !== '' ? this.offerNameMM : '',
                offerImage: this.offerImageB64 !== '' ? this.offerImageB64 : '',
                offerDescription: this.offerDescriptionEN,
                offerDescriptionMM: this.offerDescriptionMM !== '' ? this.offerDescriptionMM : '',
                offerType: this.offerType !== '' ? this.offerType : '',
                issueQuantity: this.issueQuantity,
                redemptionsPerUser: this.issueQuantityPerPerson,
                discountScope: this.selectedDiscountScope,
                scopeList: this.selectedDiscountScope === "DOCTORS" ? this.selectedDoctor : this.selectedSpecialization,
                userType: this.selectedUserType,
                discountType: this.selectedDiscountType,
                discount: this.discountValue,
                minimumSpend: this.minimumDiscountAmount ,
                maximumDiscount: this.maximumDiscountAmount ? this.maximumDiscountAmount : undefined,
                startDate: this.offerStartDate + ' ' + this.startTime +':00', 
                endDate: this.offerEndDate +' '+ this.endTime + ':00'
            }
            if(this.offerType === 'COUPON') {
                createOfferBody['offerCode'] = this.couponCode;
            }
            axios_auth_instance_admin.post('/createOffer', createOfferBody)
            .then((createOfferResponse) => {
                alert(`${this.offerNameEN} offer created successfully!!`)
                this.createOffersDialog = false;
                this.isButtonClicked = false;
                this.clearVariableOnSuccess();
            })
            .catch((createOfferError) => {
                alert(`Creating ${this.offerNameEN} failed!! - ${ createOfferError.response.data.data }`);
                this.isButtonClicked = false;
                handleError(createOfferError, this.$router, 'createOffer', 'AdminLogin', 'token');
            });
        },
        getDoctorsListForSpecializationList(){
            if (this.selectedSpecialization.length > 0){
                this.loadingDoctorList = true;
                var getDoctorsBySpecializationListBody = {
                    token : this.currentUser,
                    typeOfUser: 'ADMIN',
                    specializationList: JSON.stringify(this.selectedSpecialization)
                }
                axios_auth_instance_admin.get('/getDoctorsBySpecializationList',{params : getDoctorsBySpecializationListBody})
                .then((getDoctorsBySpecializationListResponse) => {
                    if (this.selectedDoctor.length != 0) {
                        this.listOfDoctor = [];
                        let oldSelectedDoctorList = this.selectedDoctor;
                        this.selectedDoctor = [];
                        this.listOfDoctor  = getDoctorsBySpecializationListResponse.data.data;
                        this.selectedSpecialization.forEach((specId) => {
                            let eligibleDoctor = this.listOfDoctor.find((element) => {
                                return element.specialist[0]._id == specId && oldSelectedDoctorList.includes(element._id);
                            })
                        this.selectedDoctor.push(eligibleDoctor._id);
                        this.loadingDoctorList = false;
                        })
                    } else {
                        this.listOfDoctor = getDoctorsBySpecializationListResponse.data.data;
                        this.loadingDoctorList = false;
                    }
                })
                .catch((getDoctorsBySpecializationListError) => {
                    handleError(getDoctorsBySpecializationListError, this.$router, 'getDoctorsBySpecializationList', 'AdminLogin', 'token');
                })
            }
        },
        isOfferValid(){
            return (this.isButtonClicked || !(this.offerNameEN && this.createFormData &&this.offerNameMM && this.offerDescriptionEN  && this.offerDescriptionMM  && this.offerType && this.selectedUserType && this.selectedDiscountType && this.selectedDiscountScope  && this.offerStartDate && this.startTime && this.offerEndDate && this.endTime && parseInt(this.issueQuantity) > 0 && parseInt(this.issueQuantityPerPerson) > 0 && ((parseInt(this.issueQuantity) == 1 && parseInt(this.issueQuantityPerPerson) == 1) || (parseInt(this.issueQuantityPerPerson) < parseInt(this.issueQuantity))) && ((this.selectedDiscountScope === 'DOCTORS' && this.selectedDoctor) || (this.selectedDiscountScope === 'SPECIALIZATIONS' && this.selectedSpecialization)) && parseInt(this.discountValue) > 0 && ((this.selectedDiscountType == 'FLAT_OFF' && parseInt(this.minimumDiscountAmount) >= 0) || (this.selectedDiscountType == 'PERCENTAGE_OFF' && parseInt(this.discountValue) <= 100 && parseInt(this.maximumDiscountAmount) >= 0))) || this.isCreateButtonClicked);
        },
        viewParticularOffer(dataObject){
            this.$router.push({
                name: 'ViewOffers',
                params: { offerName: dataObject.offer_code, offerStatus: this.currentStatusTab, createdAt: dataObject.createdAt }
            });
        },
        nullHandlingFunction(){
            return {}
        },
        clearVariableOnSuccess() {
            this.offerNameEN = '';
            this.offerNameMM = '';
            this.offerImageB64 = '';
            this.offerDescriptionEN = '';
            this.offerDescriptionMM = '';
            this.offerType = '';
            this.issueQuantity = null;
            this.issueQuantityPerPerson =null;
            this.selectedDiscountScope = '';
            this.selectedUserType = '';
            this.selectedDiscountType = '';
            this.discountValue = null;
            this.minimumDiscountAmount = null;
            this.maximumDiscountAmount = null;
            this.offerStartDate = '';
            this.offerEndDate = '';
            this.startTime = '00:00';
            this.endTime = '00:00';
            this.selectedSpecialization = [];
            this.selectedDoctor = [];
            this.offerImage = null;
            this.imageLoading = false;
            this.offerImagePreview = '';
        }
    }
}
</script>
<style scoped>
.actionClass{
    display: flex !important;
    flex-direction: row !important;
}
.createOffersWidth{
    width: 50%;
}
</style>
<style>
.offerTypeRadio .v-icon.v-icon{
    font-size: 12px !important;
}
</style>
